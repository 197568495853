import React, { useState, useEffect, useRef } from "react";
import Hamburger from "./Hamburger";
import { ReactComponent as Logo } from "../assets/images/SmartAfr-Nft-logo.svg";
import { ReactComponent as Profile } from "../assets/icons/profile.svg";
import ProfileDropdown from "./ProfileDropdown";
import { NavLink } from "react-router-dom";
import { useAuth } from "../context/ConnectWallet/ConnectWalletContext";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import ConnectWalletContext from "../context/ConnectWallet/ConnectWalletContext.js";

function Navbar() {
  const { address } = useAuth();
  const [showDropDown, setShowDropDown] = useState(false);
  const [Toggle, setToggle] = useState(false);

  const dropDownRef = useRef();
  const hamRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
        setShowDropDown(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handle_ClickOutside = (event) => {
      if (hamRef.current && !hamRef.current.contains(event.target)) {
        setToggle(false);
      }
    };

    document.addEventListener("click", handle_ClickOutside);
    return () => {
      document.removeEventListener("click", handle_ClickOutside);
    };
  }, []);

  function HandleDropDown() {
    setShowDropDown(!showDropDown);
  }

  function HandleToggle() {
    setToggle(!Toggle);
    console.log("success");
  }
  return (
    <>
      <nav>
        <ul>
          <li>
            <NavLink to="/">
              <Logo className="uniccon-logo" />
            </NavLink>
          </li>
        </ul>

        <div className="nav_links">
          <ul>
            <li>
              <NavLink to="/learn">Learn</NavLink>
            </li>

            <li>
              <NavLink to="/helpcenter">Help Center</NavLink>
            </li>
            <li>Blog</li>
          </ul>
          <ul>
            <ConnectButton.Custom>
              {({
                account,
                chain,
                openAccountModal,
                openChainModal,
                openConnectModal,
                authenticationStatus,
                mounted,
              }) => {
                const ready = mounted && authenticationStatus !== "loading";
                const connected =
                  ready &&
                  account &&
                  chain &&
                  (!authenticationStatus ||
                    authenticationStatus === "authenticated");

                return (
                  <div
                    {...(!ready && {
                      "aria-hidden": true,
                      style: {
                        opacity: 0,
                        pointerEvents: "none",
                        userSelect: "none",
                      },
                    })}
                  >
                    {(() => {
                      if (!connected) {
                        return (
                          <button
                            onClick={openConnectModal}
                            type="button"
                            className="connect_wallet"
                          >
                            CONNECT WALLET
                          </button>
                        );
                      }

                      if (chain.unsupported) {
                        return (
                          <button
                            onClick={openChainModal}
                            type="button"
                            className="connect_wallet"
                          >
                            Wrong network
                          </button>
                        );
                      }

                      return (
                        <div style={{ display: "flex", gap: 12 }}>
                          <button
                            onClick={openChainModal}
                            className="connect_wallet"
                            style={{ display: "flex", alignItems: "center" }}
                            type="button"
                          >
                            {chain.hasIcon && (
                              <div
                                style={{
                                  background: chain.iconBackground,
                                  width: 12,
                                  height: 12,
                                  borderRadius: 999,
                                  overflow: "hidden",
                                  marginRight: 4,
                                }}
                              >
                                {chain.iconUrl && (
                                  <img
                                    alt={chain.name ?? "Chain icon"}
                                    src={chain.iconUrl}
                                    style={{ width: 12, height: 12 }}
                                  />
                                )}
                              </div>
                            )}
                            {chain.name}
                          </button>

                          <button
                            onClick={openAccountModal}
                            type="button"
                            className="connect_wallet"
                          >
                            {account.displayName}
                          </button>
                        </div>
                      );
                    })()}
                  </div>
                );
              }}
            </ConnectButton.Custom>
          </ul>
        </div>
        <div className="nav_icons">
          {address && (
            <li onClick={HandleDropDown} ref={dropDownRef}>
              <Profile className="user-icon" />
            </li>
          )}

          <div
            className="menu-toggle-wrapper"
            onClick={HandleToggle}
            // ref={hamRef}
          >
            <button className="menu-toggle">
              <span className="menu-icon"></span>
              <span className="menu-icon"></span>
              <span className="menu-icon"></span>
            </button>
          </div>
        </div>

        {Toggle ? <Hamburger HandleToggle={HandleToggle} /> : null}
      </nav>
      {showDropDown ? <ProfileDropdown newAddress={address} /> : null}
    </>
  );
}

export default Navbar;
