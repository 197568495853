import { React } from "react";
import { Link } from "react-router-dom";
// import BuyNowModal from "./BuyNowModal";

function MyNftCard({ item }) {
  

  return (
    <>
      <div className="nft-card">
        <Link to={`/nftdetails/${item.tokenId}`}>
          <div className="nftImage">
            {/* Getting token image from tokenURI directly */}
            <img src={item.image} alt="nft" />
          </div>
        </Link>
        <div className="nft-name">
          <h3>{item.name}</h3>
          <p className="nft_price">{item.price} ETH</p>
        </div>
        <div className="nft-name">
          <div className="nft_owner_details">
            <img src={item.profileImage} alt="Owner" />
            <p>@{item.userName}</p>
          </div>
          <div className={`nft_status ${item.isListed ? "listed" : "unlisted"}`}>   
            <p>{item.isListed ? "Listed" : "Unlisted"}</p>
          </div>
        </div>
        {/* <div className="place-bid" >
          Buy now
        </div> */}
      </div>
      {/* <BuyNowModal modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} /> */}
    </>
  );
}

export default MyNftCard;
