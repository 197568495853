import { useEffect, useContext } from "react";
import NftCard from "./NftCard";
import Pagination from "./Pagination";
import AllNftContext from "../context/AllNft/AllNftContext";

// import ConnectWalletContext from "../context/ConnectWallet/ConnectWalletContext.js";


function NftCollection() {
  const {
    data,
    dataFetched,
    getAllNFTs,
    currentNft,
    nftPerPage,
    currentPage,
    setCurrentPage,
  } = useContext(AllNftContext);
 
  // const { walletConnected } = useContext(ConnectWalletContext);
 

  console.log(currentNft);
  // Waiting for getAllNFTs to run and return all data

  if (!dataFetched) getAllNFTs();
  useEffect(() => {
    async function resolveNftGet() {
      await getAllNFTs();
    }
    resolveNftGet();
  }, []);

  return (
    <>
      <div className="nft-cont">
        {currentNft &&
          currentNft.map((item) => <NftCard item={item} key={item.image} />)}
      </div>
      <Pagination
        data={data}
        nftPerPage={nftPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </>
  );
}

export default NftCollection;
