import React, { useState } from "react";
import {
  doc,
  addDoc,
  collection,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../firebase.config";
import { toast } from "react-toastify";

function Helpcenter() {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({});

  const { emailAddress, subject, message } = formData;
  console.log(formData);

  // const formOnChange = (e) => {
  //   setFormData({
  //     ...formData,
  //     [e.target.name]: e.target.value,
  //   });
  // };

  async function uploadToFirebase() {
    // After form submit
    // 1. Get values from form inputs
    const email = document.getElementById("help-email").value;
    const select = document.getElementById("help-select").value;
    const subject = document.getElementById("help-subject").value;
    const message = document.getElementById("help-message").value;
    const attachment = document.getElementById("help-file-upload").files[0]; // e.target.files[0]

    // 2. Make an object of form inputs
    const formInputs = {
      email,
      select,
      subject,
      message,
      attachment,
    };

    console.log(formInputs);

    // 3. Update formData
    setFormData(formInputs);

    // 4. Update firebase with updated formData
    try {
      console.log(formData);

      const docRef = doc(db, "help/helpDoc");
      await updateDoc(docRef, {
        mail: email.toString(),
        select: select.toString(),
        subject: subject.toString(),
        message: message.toString(),
      });
    } catch (error) {
      toast.error(error.message);
      console.log(error.message);
    }
  }

  async function formOnSubmit(e) {
    e.preventDefault();

    setLoading(true);
    await uploadToFirebase();
    setLoading(false);
    toast.success("Help info sent successfully");
    console.log("success");
  }

  return (
    <>
      <div className="help-center-cont">
        <h1>Help center</h1>
        <form onSubmit={formOnSubmit}>
          <div className="help-center">
            <label for="email">Your email address</label>
            <input
              type="text"
              id="help-email"
              name="emailAddress"
              value={emailAddress}
            ></input>
          </div>
          <div className="help-center">
            <label for="select">What do you need help with?</label>
            <select id="help-select">
              <option value="select-something"> - </option>
              <option value="artist-inquiries">Artist Inquiries</option>
              <option value="account-setup">Account Setup</option>
              <option value="media-inquiries">
                Media/Partnership Inquiries
              </option>
              <option value="general-inquiries">General Inquiries</option>
              <option value="nft-deposits">NFT Deposits & Withdrawals</option>
              <option value="eth-deposits">ETH Deposits & Withdrawals</option>
            </select>
          </div>
          <div className="help-center">
            <label for="email">Subject</label>
            <input
              type="text"
              id="help-subject"
              name="subject"
              value={subject}
            ></input>
          </div>
          <div className="help-center">
            <label for="message">Message</label>
            <textarea
              type="text"
              id="help-message"
              name="message"
              value={message}
            ></textarea>
            <p>
              Please enter the details of your request. A member of our support
              staff will respond as soon as possible.
            </p>
          </div>
          <div className="help-center">
            <p> Attachment (optional)</p>
            <label for="file-upload" class="custom-file-upload">
              <span className="add-file">
                Add file
                <input
                  id="help-file-upload"
                  type="file"
                  accept=".jpg,.png,.jpeg"
                />
              </span><br></br>
              <p>or Drop File Here</p>
            </label>
          </div>
          <button>Submit</button>
        </form>
      </div>
    </>
  );
}

export default Helpcenter;
