import React from "react";
import arrowRight from "../assets/icons/arrowRight.svg";
import { Link } from "react-router-dom";
import { ConnectButton } from "@rainbow-me/rainbowkit";

const Hamburger = () => {
  return (
    <div className="hamburgerWrapper">
      <div className="ham_cont">
        <Link to={"/learn"} className="ham_link">
          Learn
          <img src={arrowRight} alt="" />
        </Link>
        <Link to={"/helpcenter"} className="ham_link">
          Help Center
          <img src={arrowRight} alt="" />
        </Link>
        <Link to={"/helpcenter"} className="ham_link">
          Blog
          <img src={arrowRight} alt="" />
        </Link>
        <div className="ham_btn">
          <ConnectButton.Custom>
            {({
              account,
              chain,
              openAccountModal,
              openChainModal,
              openConnectModal,
              authenticationStatus,
              mounted,
            }) => {
              const ready = mounted && authenticationStatus !== "loading";
              const connected =
                ready &&
                account &&
                chain &&
                (!authenticationStatus ||
                  authenticationStatus === "authenticated");

              return (
                <div
                  {...(!ready && {
                    "aria-hidden": true,
                    style: {
                      opacity: 0,
                      pointerEvents: "none",
                      userSelect: "none",
                    },
                  })}
                >
                  {(() => {
                    if (!connected) {
                      return (
                        <button
                          onClick={openConnectModal}
                          type="button"
                          className="connectWallet"
                        >
                          CONNECT WALLET
                        </button>
                      );
                    }

                    if (chain.unsupported) {
                      return (
                        <button
                          onClick={openChainModal}
                          type="button"
                          className="connectWallet"
                        >
                          Wrong network
                        </button>
                      );
                    }

                    return (
                      <div style={{ display: "flex", gap: 12 }}>
                        <button
                          onClick={openAccountModal}
                          type="button"
                          className="connectWallet"
                        >
                          {account.displayName}
                        </button>
                      </div>
                    );
                  })()}
                </div>
              );
            }}
          </ConnectButton.Custom>
          <button className="createBtn">CREATE NFTs</button>
        </div>
      </div>
    </div>
  );
};

export default Hamburger;
