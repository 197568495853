import React, { useState } from "react";
import { db, storage } from "../firebase.config";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { doc, setDoc } from "firebase/firestore";
import { useAuth } from "../context/ConnectWallet/ConnectWalletContext";
import { toast } from "react-toastify";
import Profile_Img from "../assets/images/profile_pic.svg";
import { ReactComponent as Copy } from "../assets/icons/copy.svg";

export default function UploadProfileDetails() {
  const { address } = useAuth();
  const [selectedFile, setSelectedFile] = useState(null);
  const [editing, setEditing] = useState(false);
  const [formParams, updateFormParams] = useState({
    username: "",
    bio: "",
    emailAddress: "",
  });
  const { username, bio, emailAddress } = formParams;

  const formOnChange = (e) => {
    updateFormParams({
      ...formParams,
      [e.target.name]: e.target.value,
    });
  };

  const onChangeFile = (e) => {
    if (e.target.files[0]) {
      setSelectedFile(e.target.files[0]);
    }
  };

  const uploadFileToStorage = async (file) => {
    const storageRef = ref(storage, `profileImages/${address}`);
    await uploadBytes(storageRef, file);
    const fileUrl = await getDownloadURL(storageRef);
    return fileUrl;
  };

  const formOnSubmit = async (e) => {
    e.preventDefault();
    setEditing(true);

    try {
      let imageUrl = "";
      if (selectedFile) {
        console.log("selectedFile", selectedFile);
        imageUrl = await uploadFileToStorage(selectedFile);
      }
      const formDataCopy = {
        userRef: address,
        profileImage: imageUrl,
        ...formParams,
      };
      const docRef = doc(db, "users", address);
      await setDoc(docRef, formDataCopy);
      toast.success("Profile updated successfully");
      updateFormParams({
        username: "",
        bio: "",
        emailAddress: "",
      });
      setSelectedFile(null);
      setEditing(false);
    } catch (error) {
      setEditing(false);
      console.log(error);
    }
  };

  return (
    <div className="edit-cont">
      <h1>Edit Profile</h1>
      <div className="edit-form">
        <div className="edit_profile_img">
          <div>
            <img src={Profile_Img} alt="profile" />
          </div>
          {/* <p>Replace</p> */}
          <input type={"file"} onChange={onChangeFile} />
        </div>
        <div className="edit-input">
          <p>Wallet address</p>
          <div className="edit_wal_add">
            <p>0X2I39GVGW452923dboiwef78433123</p>
            <div>
              <Copy className="edit_wal_add_img" />
            </div>
          </div>
        </div>
        <form onSubmit={formOnSubmit}>
          <div className="edit-input">
            <label for="name">Username</label>
            <input
              type="text"
              id="name"
              name="username"
              placeholder="Enter your username"
              onChange={formOnChange}
              value={username}
            ></input>
          </div>

          <div className="edit-input">
            <label for="bio">Bio</label>
            <textarea
              type="text"
              id="text"
              name="bio"
              placeholder="A brief description of yourself"
              value={bio}
              onChange={formOnChange}
            ></textarea>
          </div>
          <div className="edit-input">
            <label for="email">Email Address</label>
            <input
              type="text"
              id="text"
              name="emailAddress"
              placeholder="Enter your email"
              onChange={formOnChange}
              value={emailAddress}
            ></input>
          </div>
          <div className="edit-btn">
            <button> Submit</button>
            {editing && <div className="loader"></div>}
          </div>
        </form>
      </div>
    </div>
  );
}
