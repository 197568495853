import { createContext, useContext, useState } from "react";
// import { db } from "../../firebase.config";
// import { doc, setDoc, getDoc, serverTimestamp } from "firebase/firestore";
import { useAccount } from "wagmi";
// import { RainbowKitProvider } from "@rainbow-me/rainbowkit";


const ConnectWalletContext = createContext();

export const Homes = ({ children }) => {

  const { address, isConnected } = useAccount();
  const [walletConnected, setWalletConnected] = useState(false);

  // async function checkWallet() {
  //   try {
  //     const signer = RainbowKitProvider.getSigner();
  //     const addr = await signer.getAddress();

  //     // Check if the user already exists in the database
  //     const docRef = doc(db, "users", addr);
  //     const docSnap = await getDoc(docRef);

  //     // If the user does not exist, create a new user
  //     if (!docSnap.exists()) {
  //       await setDoc(doc(db, "users", addr), {
  //         address: addr,
  //         timestamp: serverTimestamp(),
  //       });
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  return (
    <ConnectWalletContext.Provider
      value={{ address, walletConnected, setWalletConnected }}
    >
      {children}
    </ConnectWalletContext.Provider>
  );
};

export const useAuth = () => useContext(ConnectWalletContext);
