import { useParams } from "react-router-dom";
import MarketplaceJSON from "../Marketplace.json";
import axios from "axios";
import { useState } from "react";
import { ethers } from "ethers";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase.config";

export default function NftDetails(props) {
  const [data, updateData] = useState({});
  const [dataFetched, updateDataFetched] = useState(false);
  const [message, updateMessage] = useState("");
  const [currAddress, updateCurrAddress] = useState("0x0");
  const [adminAcc, setAdminAcc] = useState("");
  const [transferring, setTransferring] = useState(false);
  const [listing, setListing] = useState(false);
  const [unlisting, setUnlisting] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [sharing, setSharing] = useState(false);
  const [buying, setBuying] = useState(false);

  const contractObj = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const addr = await signer.getAddress();
    const contract = new ethers.Contract(
      MarketplaceJSON.address,
      MarketplaceJSON.abi,
      signer
    );
    const admin = await contract.getOwner();

    return { provider, signer, addr, contract, admin };
  };

  async function getNFTData(tokenId) {
    //After adding your Hardhat network to your metamask, this code will get providers and signers

    //Pull the deployed contract instance
    //create an NFT Token
    const { admin, addr, contract } = await contractObj();
    setAdminAcc(admin);
    const tokenURI = await contract.tokenURI(tokenId);
    const listedToken = await contract.getNFTMetadata(tokenId);
    let meta = await axios.get(tokenURI);
    meta = meta.data;
    console.log(listedToken);
    console.log(meta);

    const fetchUserDetails = async () => {
      const userRef = doc(db, "users", listedToken.seller);
      const userSnapshot = await getDoc(userRef);
      if (userSnapshot.exists()) {
        const userData = userSnapshot.data();
        console.log("User data: ", userData);
        return userData;
      }
    };
    const getUserData = await fetchUserDetails();
    console.log(getUserData);

    let item = {
      price: meta.price,
      tokenId: tokenId,
      seller: listedToken.seller,
      owner: listedToken.owner,
      image: meta.image,
      name: meta.name,
      description: meta.description,
      isListed: listedToken.currentlyListed,
      profileImage: getUserData?.profileImage,
      userName: getUserData?.username,
    };
    console.log(item);
    updateData(item);
    updateDataFetched(true);
    console.log("address", addr);
    updateCurrAddress(addr);
    console.log(item.isListed);
  }
  console.log("address", currAddress);

  // Function to list NFT
  const listMyNft = async () => {
    setListing(true);
    const { contract } = await contractObj();
    const actualPrice = data.price;
    const price = ethers.utils.parseUnits(actualPrice.toString(), "ether");
    updateMessage("Please wait.. listing (This may take up to 2 mins)");
    let listingPrice = await contract.getListPrice();
    console.log(listingPrice);
    console.log(price);
    const transaction = await contract.listNft(data.tokenId, price, {
      value: listingPrice,
    });
    await transaction.wait();
    setListing(false);
    window.location.replace("/");
  };

  // Function to unlist NFT
  const unListNft = async () => {
    setUnlisting(true);
    const { contract } = await contractObj();
    updateMessage("Please wait.. unlisting (This may take up to 2 mins)");

    const transaction = await contract.unlistToken(data.tokenId);
    await transaction.wait();
    setUnlisting(false);
    window.location.replace("/");
  };

  const DeleteNft = async () => {
    setDeleting(true);
    const { contract } = await contractObj();
    updateMessage("Please wait.. deleting (This may take up to 2 mins)");

    const transaction = await contract.deleteNfts(data.tokenId);
    await transaction.wait();
    setDeleting(false);
    window.location.replace("/");
  };

  const shareNft = async () => {
    setSharing(true);
    let reciever = document.getElementById("transfernft").value;

    const { contract } = await contractObj();
    updateMessage("Please wait.. sharing (This may take up to 2 mins)");

    const transaction = await contract.shareNft(
      reciever.toString(),
      data.tokenId
    );
    await transaction.wait();
    setTransferring(false);
    setSharing(false);
    window.location.replace("/");
    reciever = "";
  };

  async function buyNFT(tokenId) {
    setBuying(true);
    const { contract } = await contractObj();
    try {
      const salePrice = ethers.utils.parseUnits(data.price, "ether");
      updateMessage(
        "Buying the NFT... Please Wait (This may take Up to 2 mins)"
      );
      //run the executeSale function
      let transaction = await contract.BuyNft(tokenId, { value: salePrice });
      await transaction.wait();

      alert("You successfully bought the NFT!");
      updateMessage("");
      setBuying(false);
      window.location.replace("/profile");
    } catch (e) {
      alert("Upload Error" + e);
      setBuying(false);
    }
  }

  const params = useParams();
  const tokenId = params.tokenId;
  if (!dataFetched) getNFTData(tokenId);

  return (
    <div className="nft-details-cont">
      <div className="nft-details-img">
        <img src={data.image} alt="nft" />
      </div>
      <div className="nft-details-right">
        <div className="nft-details_name-price">
          <h1>{data.name}</h1>
          <p>
            {data.price} <span>ETH</span>
          </p>
        </div>
        <div className="nft-details_owner">
          <div className="nft-details_ownerDetails">
            <div className="nft-details_ownerImg">
              <img src={data.profileImage} alt="Owner" />
            </div>
            <div>
              <p>Owned by</p>
              <h4>{data.userName}</h4>{" "}
            </div>
          </div>
        </div>
        <div className="nft-details_description">
          <h2>Description</h2>
          <p>{data.description}</p>
        </div>
        {/* <div className="nft-details-own">
          <Avatar />
          <div>
            <p>Owned by</p>
            <p className="nft-details-ownner">{data.seller}</p>
          </div>
        </div> */}
        {/* <p>Token ID: {data.tokenId}</p> */}

        {currAddress !== data.seller && currAddress !== "0x0" ? (
          <div className="nft-details_buy-cont">
            <button className="nft-details_buy" onClick={() => buyNFT(tokenId)}>
              Buy NFT
            </button>
            {buying && <div className="loader"></div>}
            {currAddress === adminAcc && (
              <>
                <button className="nft-details_list" onClick={unListNft}>
                  Unlist
                </button>
                {unlisting && <div className="loader"></div>}
              </>
            )}
          </div>
        ) : (
          <div className="nft-details_btns-cont">
            <button
              className="nft-details_transfer"
              onClick={() => {
                setTransferring(true);
              }}
            >
              Transfer Nft
            </button>
            {transferring && (
              <div className="nft-transfer">
                <div>
                  <input
                    className="nft-transfer-input"
                    type="text"
                    id="transfernft"
                    placeholder="Recipient Address"
                  />
                </div>
                <div className="nft-transfer-options">
                  <label></label>
                  <button
                    className="nft-transfer-cancel"
                    onClick={() => {
                      setTransferring(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button className="nft-transfer-btn" onClick={shareNft}>
                    Transfer
                  </button>
                  {sharing && <div className="loader"></div>}
                </div>
              </div>
            )}

            {data.isListed ? (
              <>
                <button className="nft-details_list" onClick={unListNft}>
                  Unlist
                </button>
                {unlisting && <div className="loader"></div>}
                <button className="nft-details_blank">blank</button>
                <button className="nft-details_delete" onClick={DeleteNft}>
                  Delete Nft
                </button>
                {deleting && <div className="loader"></div>}
              </>
            ) : (
              <>
                <button className="nft-details_list" onClick={listMyNft}>
                  List
                </button>
                {listing && <div className="loader"></div>}
                <button className="nft-details_blank">blank</button>
                <button className="nft-details_delete" onClick={DeleteNft}>
                  Delete Nft
                </button>
                {deleting && <div className="loader"></div>}
              </>
            )}
          </div>
        )}

        <div className="buy-now">{message}</div>
      </div>
    </div>
  );
}
