import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Eth } from "../assets/icons/ethicon.svg";

function ProfileDropdown({newAddress}) {
  return (
    <div className="profile-dropdown">
      <div className="wallet-add">
        <p> Address</p>
        <div className="drop-down-add" >
          <Eth />
          <p>{newAddress ? newAddress : ""} </p>
        </div>
      </div>

      <div className="profile-dropdown-item">
        <Link to="/profile">
          <p>My Profile</p>
        </Link>
      </div>

      <div className="profile-dropdown-item">
        <Link to="/editprofile">
          <p>Edit Profile</p>
        </Link>
      </div>

      <div className="profile-dropdown-item">
        <p>Sign Out</p>
      </div>
    </div>
  );
}
export default ProfileDropdown;
