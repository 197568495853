import React from "react";
import { ReactComponent as Facebook } from "../assets/icons/Facebook.svg";
import { ReactComponent as Telegram } from "../assets/icons/Telegram.svg";
import { ReactComponent as Frame } from "../assets/icons/Frame 78.svg";
import { ReactComponent as Twitter } from "../assets/icons/Twitter.svg";
import { ReactComponent as Discord } from "../assets/icons/Discord.svg";
import { ReactComponent as Linkedin } from "../assets/icons/Linkedin.svg";

function Footer() {
  return (
    <>
      <footer>
        <div className="footer-first-cont">
          <div>
            <h3>Stay in the loop</h3>
            <p>
              Join our mailing list to receive the newest feature releases, NFT
              drops and latest news
            </p>
            <form>
              <input type="email" placeholder="Enter your email" />
              <button>Sign up</button>
            </form>
          </div>
          <div>
            <h3>Learn</h3>
            <ul>
              <li>Getting Started</li>
              <li>How to Buy</li>
              <li>How to Sell</li>
            </ul>
          </div>
          <div className="footer-socials">
            <h3>Join the community</h3>
            <ul>
              <li>
                <Facebook />
              </li>
              <li>
                <Telegram />
              </li>
              <li>
                <Frame />
              </li>
              <li>
                <Twitter />
              </li>
              <li>
                <Discord />
              </li>
              <li>
                <Linkedin />
              </li>
            </ul>
          </div>
        </div>

        <hr></hr>
        <div className="footer-sec-cont">
          <p>© 2022 SmartAfri. All rights reserved.</p>
          <p>Terms of Service</p>
          <p>Privacy Policy</p>
        </div>
      </footer>
    </>
  );
}

export default Footer;
