import { React, useState, useEffect, useContext } from "react";
import MyNftCard from "../Components/MyNftCard";
import MyNftContext from "../context/MyNft/MyNftContext";
import Pagination from "./Pagination";


function MyNfts() {
  const {
    data,
    dataFetched,
    tokenId,
    currentNft,
    nftPerPage,
    currentPage,
    getNFTMetadata,
    setCurrentPage,
  } = useContext(MyNftContext);

  if (dataFetched) getNFTMetadata(tokenId);
  

  return (
    <div>
      <div>
        <div>
          {console.log(data)}
          <div className="nft-cont">
            {currentNft &&
              currentNft.map((item, index) => (
                <MyNftCard item={item} key={index} />
              ))}
          </div>
          <div>
            {data?.length === 0
              ? "Oops, No NFT data to display (Are you logged in?)"
              : ""}
          </div>
        </div>
        <Pagination
          data={data}
          nftPerPage={nftPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
}
export default MyNfts;
