import { createContext, useState } from "react";
import MarketplaceJSON from "../../Marketplace.json";
import { ethers } from "ethers";
import axios from "axios";
import UserDetailsContext from "../UserDetails/UserDetailsContext.jsx";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase.config";

const AllNftContext = createContext();

export const AllNftProvider = ({ children }) => {
  const [data, updateData] = useState([]);
  const [dataFetched, updateDataFetched] = useState(false);
  // const [nfts, setNfts] = useState([]);

  //Pagination logic
  const [currentPage, setCurrentPage] = useState(1);
  const [nftPerPage] = useState(8);

  const indexOfLastNumber = currentPage * nftPerPage;
  const indexOfFirstNumber = indexOfLastNumber - nftPerPage;
  const currentNft = data?.slice(indexOfFirstNumber, indexOfLastNumber);

  async function getAllNFTs() {
    //After adding your Hardhat network to your metamask, this code will get providers and signers
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    //Pull the deployed contract address from the Marketplace.json file
    let contract = new ethers.Contract(
      MarketplaceJSON.address,
      MarketplaceJSON.abi,
      signer
    );
    //create an NFT Token
    let transaction = await contract.getAllNFTs();

    console.log(transaction);

    const items = await Promise.all(
      transaction.map(async (i) => {
        const tokenURI = await contract.tokenURI(i.tokenId);

        //const tokenURI = await contract.tokenURI(i.tokenId);
        let meta = await axios.get(tokenURI);

        meta = meta.data;
        const fetchUserDetails = async () => {
          const userRef = doc(db, "users", i.seller);
          const userSnapshot = await getDoc(userRef);
          if (userSnapshot.exists()) {
            const userData = userSnapshot.data();
            console.log("User data: ", userData);
            return userData;
          }
        };
        const getUserData = await fetchUserDetails();
        console.log(getUserData);

        let price = ethers.utils.formatUnits(i.price.toString(), "ether");
        let item = {
          price,
          tokenId: i.tokenId.toNumber(),
          seller: i.seller,
          owner: i.owner,
          image: meta.image,
          name: meta.name,
          description: meta.description,
          profileImage: getUserData?.profileImage,
          userName: getUserData?.username,
        };

        return item;
      })
    );

    updateDataFetched(true);
    updateData(items.reverse());
  }

  return (
    <AllNftContext.Provider
      value={{
        data,
        dataFetched,
        getAllNFTs,
        currentNft,
        nftPerPage,
        currentPage,
        setCurrentPage,
      }}
    >
      {children}
    </AllNftContext.Provider>
  );
};

export default AllNftContext;
