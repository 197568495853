import { createContext, useState } from "react";
import { useAuth } from "../ConnectWallet/ConnectWalletContext.js";
import { db } from "../../firebase.config";
import { doc, getDoc } from "firebase/firestore";

const UserDetailsContext = createContext();

export const UserDetailsProvider = ({ children }) => {
  const { address } = useAuth();
  const [datas, updateDatas] = useState();

  // fetch user details
  const fetchUserDetails = async () => {
    const userRef = doc(db, "users", address);
    const userSnapshot = await getDoc(userRef);
    if (userSnapshot.exists()) {
      const userData = userSnapshot.data();
      updateDatas(userData);
      console.log("User data: ", userData);
    }
  };
  console.log(datas);

  //fetch user profile image


  return (
    <UserDetailsContext.Provider value={{datas, updateDatas, fetchUserDetails, address}}>
      {children}
    </UserDetailsContext.Provider>
  );
};

export default UserDetailsContext;
