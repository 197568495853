import { useEffect, useContext } from "react";
import { ReactComponent as Copy } from "../assets/icons/copy.svg";
import MyNfts from "../Components/MyNfts";
import MyNftContext from "../context/MyNft/MyNftContext";
import UserDetailsContext from "../context/UserDetails/UserDetailsContext";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

function Profile() {
  const { datas, fetchUserDetails, address } =
    useContext(UserDetailsContext);
  const { data, totalPrice } = useContext(MyNftContext);
  // const { address } = useAuth();
  // const [datas, updateDatas] = useState();

  useEffect(() => {
    fetchUserDetails();
  }, [address]);

  // fetch user details
  // const fetchUserDetails = async () => {
  //   const userRef = doc(db, "users", address);
  //   const userSnapshot = await getDoc(userRef);
  //   if (userSnapshot.exists()) {
  //     const userData = userSnapshot.data();
  //     updateDatas(userData);
  //     console.log("User data: ", userData)

  //   }
  // };
  // console.log(datas);

  // copy wallet address
  const CopyWalletAddress = () => {
    navigator.clipboard.writeText(address);
    toast.success("Wallet Address Copied");
  };

  return (
    <>
      <div className="profile-cont">
        <section className="profile_cont_first_sect">
          <div className="profile_main_info">
            <div className="profile_img">
              <img src={datas?.profileImage} alt="profile" />
            </div>
            <div className="profile_info">
              <div className="profile_info_sect_one">
                <h1>{datas?.username}</h1>
                <div className="profile_add">
                  <p>{address}</p>
                  <Copy onClick={CopyWalletAddress} />
                </div>
              </div>
              <div className="profile_info_sect_two">
                <div>
                  <p>No. of NFTs</p>
                  <h1>{data?.length}</h1>
                </div>
                <div>
                  <p>Total Value</p>
                  <h1>{totalPrice} ETH</h1>
                </div>
              </div>
            </div>
          </div>
          <div className="profile_bio_sect">
            <h1>Bio</h1>
            <p>{datas?.bio}</p>
          </div>
        </section>
        <section className="profile_buttons">
          <Link to="/editprofile">
            <button className="create-btn">Edit Profile</button>
          </Link>
          <Link to="/create">
            <button className="connect_wallet">CREATE NFTs</button>
          </Link>
        </section>

        {/* <div className="profile-card">
        <Avatar className="profile-img" />
        <h1>{data?.username}</h1>
        <button className="profile-wal-add" onClick={CopyWalletAddress}>
          <Eth />
          <p>{address}</p>
        </button>
        <h3>{data?.bio}</h3>
      </div>
      <div className="profile-nft-sect">
        <h1>My NFTs</h1>
        <MyNfts />
      </div> */}
      </div>
      <section className="profile-nft-sect">
        <h1>NFT Collection</h1>
        <MyNfts />
      </section>
    </>
  );
}

export default Profile;
