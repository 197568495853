// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCc1o3FEIpp2OkV3pKclYE7MGgXSDUV97Y",
  authDomain: "blockchain-b779a.firebaseapp.com",
  projectId: "blockchain-b779a",
  storageBucket: "blockchain-b779a.appspot.com",
  messagingSenderId: "667913358267",
  appId: "1:667913358267:web:fd5f9927f4dcd3e1ee929e",
  measurementId: "G-BF3P750XQ3",
};

// Initialize Firebase
const initFirestoreApp = initializeApp(firebaseConfig);
export const db = getFirestore();
export const storage = getStorage(initFirestoreApp);
export default initFirestoreApp;
