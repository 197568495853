import { createContext, useState, useEffect } from "react";
import MarketplaceJSON from "../../Marketplace.json";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useAuth } from "../ConnectWallet/ConnectWalletContext";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase.config";

const MyNftContext = createContext();

export const MyNftProvider = ({ children }) => {
  const { address: userAddress } = useAuth();
  const [data, updateData] = useState([]);
  const [dataFetched, updateFetched] = useState(false);
  const [address, updateAddress] = useState("0x");
  const [totalPrice, updateTotalPrice] = useState("0");

  // Pagination logic
  const [currentPage, setCurrentPage] = useState(1);
  const [nftPerPage] = useState(4);
  const indexOfLastNumber = currentPage * nftPerPage;
  const indexOfFirstNumber = indexOfLastNumber - nftPerPage;
  let currentNft = data?.slice(indexOfFirstNumber, indexOfLastNumber);

  const params = useParams();
  const tokenId = params.tokenId;

  async function getNFTMetadata(tokenId) {
    const ethers = require("ethers");
    let sumPrice = 0;
    //After adding your Hardhat network to your metamask, this code will get providers and signers
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const addr = await signer.getAddress();

    //Pull the deployed contract instance
    let contract = new ethers.Contract(
      MarketplaceJSON.address,
      MarketplaceJSON.abi,
      signer
    );

    //create an NFT Token
    let transaction = await contract.getMyNFTs();

    //Below function takes the metadata from tokenURI and the data returned by getMyNFTs() contract function
    //and creates an object of information that is to be displayed

    const items = await Promise.all(
      transaction.map(async (i) => {
        const tokenURI = await contract.tokenURI(i.tokenId);
        let meta = await axios.get(tokenURI);
        meta = meta.data;

        const fetchUserDetails = async () => {
          const userRef = doc(db, "users", i.seller);
          const userSnapshot = await getDoc(userRef);
          if (userSnapshot.exists()) {
            const userData = userSnapshot.data();
            console.log("User data: ", userData);
            return userData;
          }
        };
        const getUserData = await fetchUserDetails();

        let price = ethers.utils.formatUnits(i.price.toString(), "ether");
        let item = {
          price,
          tokenId: i.tokenId.toNumber(),
          seller: i.seller,
          owner: i.owner,
          image: meta.image,
          name: meta.name,
          description: meta.description,
          isListed: i.currentlyListed,
          profileImage: getUserData.profileImage,
          userName: getUserData.username,
        };
        sumPrice += Number(price);
        return item;
      })
    );

    updateData(items.reverse());
    updateFetched(false);
    updateAddress(addr);
    updateTotalPrice(sumPrice.toPrecision(3));
  }

  useEffect(() => {
    if (userAddress === null) {
      updateData([]);
    } else {
      updateFetched(true);
    }
  }, [userAddress]);

  if (dataFetched) getNFTMetadata(tokenId);

  return (
    <MyNftContext.Provider
      value={{
        data,
        totalPrice,
        currentNft,
        nftPerPage,
        currentPage,
        setCurrentPage,
        getNFTMetadata,
        dataFetched,
        tokenId,
      }}
    >
      {children}
    </MyNftContext.Provider>
  );
};

export default MyNftContext;
