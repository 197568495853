import { useState, useEffect, useContext } from "react";
import { ReactComponent as LeftArrow } from "../assets/icons/LeftArrow.svg";
import { ReactComponent as RightArrow } from "../assets/icons/RightArrow.svg";


function Pagination({ data, nftPerPage, currentPage, setCurrentPage }) {
  const [disabledPrev, setDisabledPrev] = useState(true);
  const [disabledNext, setDisabledNext] = useState(true);
  

 // Pagination logic
 
 const numberOfPages = Math.ceil(data.length / nftPerPage);

  const pageNumber = [...Array(numberOfPages + 1).keys()].slice(1);

  const prevBtn = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextBtn = () => {
    if (currentPage !== numberOfPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    if (currentPage > 1) {
      setDisabledPrev(false);
    } else {
      setDisabledPrev(true);
    }

    if (currentPage === numberOfPages) {
      setDisabledNext(true);
    } else {
      setDisabledNext(false);
    }
  }, [currentPage, numberOfPages]);

  return (
    <div>
      <section className="pagination">
        <div className={disabledPrev ? "disabled" : "prev"} onClick={prevBtn}>
          <div className="arrow">
            <LeftArrow />
          </div>
          <p> Prev</p>
        </div>
        <div className="pagination-num">
          {pageNumber.map((num) => (
            <div key={num}>
              <p
                onClick={() => setCurrentPage(num)}
                className={(currentPage === num ? "pagination-child-active" : "") + " " + "pagination-child"}

              >
                {num}
              </p>
            </div>
          ))}
        </div>
        <div className={disabledNext ? "disabled" : "next"} onClick={nextBtn}>
          <p>Next</p>
          <div className="arrow">
            <RightArrow />
          </div>
        </div>
      </section>
    </div>
  );
}

export default Pagination;
