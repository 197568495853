import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../context/ConnectWallet/ConnectWalletContext";
import NftCollection from "../Components/Nftcollection";
import { ConnectButton } from "@rainbow-me/rainbowkit";

function Home() {
  const { connectWallet, Modal, disconnect } = useAuth();
  return (
    <>
      <div className="home-cont">
        <div className="first-cont">
          <h1>
            THE NEW{" "}
            <span className="mp-text">
              MARKET<br></br>PLACE
            </span>{" "}
            FOR <span className="n-text">NFT</span>
          </h1>
          <p>
            African's largest digital market place for collectibles<br></br> and
            non-fungible tokens
          </p>
          <div className="btn-cont">
            <ConnectButton.Custom>
              {({
                account,
                chain,
                openAccountModal,
                openChainModal,
                openConnectModal,
                authenticationStatus,
                mounted,
              }) => {
                const ready = mounted && authenticationStatus !== "loading";
                const connected =
                  ready &&
                  account &&
                  chain &&
                  (!authenticationStatus ||
                    authenticationStatus === "authenticated");

                return (
                  <div
                    {...(!ready && {
                      "aria-hidden": true,
                      style: {
                        opacity: 0,
                        pointerEvents: "none",
                        userSelect: "none",
                      },
                    })}
                  >
                    {(() => {
                      if (!connected) {
                        return (
                          <button
                            onClick={openConnectModal}
                            type="button"
                            className="connect_wallet"
                          >
                            CONNECT WALLET
                          </button>
                        );
                      }

                      if (chain.unsupported) {
                        return (
                          <button
                            onClick={openChainModal}
                            type="button"
                            className="connect_wallet"
                          >
                            Wrong network
                          </button>
                        );
                      }

                      return (
                        <div style={{ display: "flex", gap: 12 }}>
                          <button
                            onClick={openAccountModal}
                            type="button"
                            className="connect_wallet"
                          >
                            {account.displayName}
                          </button>
                        </div>
                      );
                    })()}
                  </div>
                );
              }}
            </ConnectButton.Custom>
            {/* {!(Modal && Modal.cachedProvider) && (
              <button onClick={connectWallet} className="connect_wallet">
                CONNECT WALLET
              </button>
            )}
            {Modal && Modal.cachedProvider && (
              <button onClick={disconnect} className="connect_wallet">
                DISCONNECT WALLET
              </button>
            )} */}

            <Link to="/create">
              <button className="create-btn">CREATE NFTs</button>
            </Link>
          </div>
        </div>
        <h1 className="AllNFTs">All NFTs</h1>
        <NftCollection />
      </div>
    </>
  );
}

export default Home;
