import { React, useContext, useEffect } from "react";
import { Link } from "react-router-dom";

// import OwnerImage from "../assets/images/owner_img.svg";
// import BuyNowModal from "./BuyNowModal";

function NftCard({ item }) {


  // const [modalIsOpen, setIsOpen] = useState(false);

  // function openModal() {
  //   setIsOpen(true);
  // }
 
  return (
    <>
      <div className="nft-card">
        <Link to={`/nftdetails/${item.tokenId}`}>
          <div className="nftImage">
            {/* Getting token image from tokenURI directly */}
            <img src={item.image} alt="nft" />
          </div>
        </Link>
        <div className="nft-name">
          <h3>{item.name}</h3>
          <p>{item.price} ETH</p>
        </div>
        <div className="nft-name">
          <div className="nft_owner_details">
            <img src={item.profileImage} alt="Owner" />
            <p>@{item.userName}</p>
          </div>
          {/* <div className="nft_status">
            <p>Unlisted</p>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default NftCard;
