import React from "react";
import { ReactComponent as Frame } from "../assets/images/LEARN.svg";
import { ReactComponent as GamePad } from "../assets/icons/Gamepad_light.svg";
import { ReactComponent as WalletIcon } from "../assets/icons/Wallet_alt_fill.svg";
import { ReactComponent as ImgBox } from "../assets/icons/Img_box_duotone.svg";

function Learn() {
  return (
    <>
      <div className="learn-cont">
        <div className="learn-first-sect">
          <Frame className="frame" />
          {/* <div className="frame-text">
            <h1>Create</h1>
            <h1>Integrate</h1>
            <h1>Sell</h1>
          </div> */}
        </div>
        <div className="learn-second-sect">
          <h1>Getting Started</h1>
          <div>
            <div className="learn-second-sect-box">
              <div className="learn-get-started">
                <div className="learn-second-sect-box-icons">
                  <GamePad />
                  <h4>SETUP</h4>
                </div>
                <h2>Setup a MetaMask Wallet</h2>
              </div>
              <div>
                <p>
                  Download your favourite blockchain wallet. Setup your security
                  details (Seed Phrase & Password), we suggest MetaMask for easy
                  usage.
                </p>
              </div>
            </div>
            <div className="learn-second-sect-box">
              <div className="learn-get-started">
                <div className="learn-second-sect-box-icons">
                  <WalletIcon />
                  <h4>FUND ETH</h4>
                </div>
                <h2>Fund MetaMask with ETH</h2>
              </div>
              <p>
                Ether is the native currency of the Ethereum network and it's
                commonly abbreviated to ETH, which is its most common signifier.
                You need ETH to pay for some of your interactions with the
                blockchain, and to pay for the NFTs that you buy and/or create.
                You can also fund your wallet with SmartAfri Tokens.
              </p>
            </div>
            <div className="learn-second-sect-box">
              <div className="learn-get-started">
                <div className="learn-second-sect-box-icons">
                  <ImgBox />
                  <h4>FIND NFT</h4>
                </div>
                <h2>Create, Buy & Sell NFTs</h2>
              </div>
              <p>
                Connect your wallet to SmartAfri NFT marketplace. Use the create
                button to upload your file(s) to the market place. Place your
                NFT on the blockchain after creation, set a price and put it up
                for sale.
              </p>
            </div>
          </div>
        </div>
        <div className="learn-third-sect">
          <h1>
            Create and Sell Your <span className="NFTs">NFTs</span>
          </h1>
          <div className="learn-third-sect-box-cont">
            <div className="learn-third-sect-mini-box">
              <h3>01.</h3>
              {/* <hr></hr> */}
              <p>Set up your wallet</p>
            </div>
            <div className="learn-third-sect-box big-box-one">
              <h3>02.</h3>
              <div className="learn-third-sect-box-content">
                <h3>Create your NFT</h3>
                <p>
                  Upload your NFT to the blockchain. Hold SmartAfri Token to
                  receive lower fees and facilitate cheaper transactions on the
                  market. Set a price and place your NFT for sale.
                </p>
              </div>
            </div>
            <div className="learn-third-sect-box big-box-two">
              <h3>03.</h3>
              <div className="learn-third-sect-box-content">
                <h3>Add your NFT </h3>
                <p>
                  Select your file(Image, Audio or Video), original contents are
                  often more valuable. Specify your NFT details, attach links
                  where applicable.
                </p>
              </div>
            </div>
            <div className="learn-third-sect-mini-box">
              <h3>04.</h3>
              <p>List them for sale</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Learn;
