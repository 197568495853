import { Routes, Route } from "react-router-dom";
import "./App.css";
import Navbar from "./Components/Navbar.jsx";
import Home from "./pages/home";
import Create from "./pages/create";
import Learn from "./pages/learn";
import Helpcenter from "./pages/helpcenter";
import Profile from "./pages/profile";
import NftDetails from "./pages/nftDetails";
import EditProfile from "./pages/edit-profile";
import Footer from "./Components/Footer";
import { ToastContainer } from "react-toastify";
import { AllNftProvider } from "./context/AllNft/AllNftContext";
import { MyNftProvider } from "./context/MyNft/MyNftContext";
import { UserDetailsProvider } from "./context/UserDetails/UserDetailsContext";
//import { ConnectWalletProvider } from "./context/ConnectWallet/ConnectWalletContext";
import "react-toastify/dist/ReactToastify.css";
//import Hamburger from "./Components/Hamburger";

function App() {
  return (
    <>
      <AllNftProvider>
        <MyNftProvider>
          <UserDetailsProvider>
            <div className="App">
              <Navbar />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/create" element={<Create />} />
                <Route path="/learn" element={<Learn />} />
                <Route path="/helpcenter" element={<Helpcenter />} />
                <Route path="/nftdetails/:tokenId" element={<NftDetails />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/editprofile" element={<EditProfile />} />
              </Routes>
              <ToastContainer />
              <Footer />
            </div>
          </UserDetailsProvider>
        </MyNftProvider>
      </AllNftProvider>
    </>
  );
}

export default App;
