import React, { useState } from "react";
import { uploadFileToIPFS, uploadJSONToIPFS } from "../pinata";
import Marketplace from "../Marketplace.json";
import { toast } from "react-toastify";
import Upload from "../assets/icons/upload.svg";

export default function UploadFile() {
  //state for checkbox
  const [checked, setChecked] = useState(false);

  //state for forms
  const [formParams, updateFormParams] = useState({
    name: "",
    description: "",
    price: "",
  });

  //state for file
  const [fileURL, setFileURL] = useState("");
  const [fileName, setFileName] = useState(null);
  const [creating, setCreating] = useState(false);

  //storing ethers in variable
  const ethers = require("ethers");

  //state for upload message
  const [message, updateMessage] = useState("");
  // const location = useLocation();

  //event function for file change
  async function OnChangeFile(e) {
    var file = e.target.files[0];
    setFileName(file.name);
    //check for file extension
    try {
      //upload the file to IPFS
      const response = await uploadFileToIPFS(file);
      console.log("Response: ", response);
      if (response.success === true) {
        console.log("Uploaded image to Pinata: ", response.pinataURL);
        setFileURL(response.pinataURL);
        document.getElementById("fileName").innerText = file.name;
        toast.success("File uploaded successfully");
        console.log("success");
      }
    } catch (e) {
      console.log("Error during file upload", e);
    }
  }

  //even function for handling checkbox
  const handleCheckbox = (e) => {
    if (e.target.checked) {
      console.log("Checkbox is checked");
    } else {
      console.log("Checkbox is NOT checked");
    }
    setChecked((current) => !current);
  };

  //This function uploads the metadata to IPFS
  async function uploadMetadata() {
    const { name, description, price } = formParams;
    //Make sure that none of the fields are empty
    if (!name || !description || !price || !fileURL) return;

    const nftJSON = {
      name,
      description,
      price,
      image: fileURL,
    };

    console.log("JSON: ", nftJSON);

    try {
      //upload the metadata JSON to IPFS
      const response = await uploadJSONToIPFS(nftJSON);
      if (response.success === true) {
        console.log("Uploaded JSON to Pinata: ", response);
        return response.pinataURL;
      }
    } catch (e) {
      console.log("error uploading JSON metadata:", e);
    }
  }

  async function createNFT(e) {
    e.preventDefault();
    setCreating(true);

    //Upload data to IPFS
    try {
      const metadataURL = await uploadMetadata();
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();

      let contract = new ethers.Contract(
        Marketplace.address,
        Marketplace.abi,
        signer
      );

      const actualPrice = formParams.price;

      const price = ethers.utils.parseUnits(actualPrice.toString(), "ether");
      updateMessage("Please wait.. uploading (This may take up to 2 mins)");
      let listingPrice = await contract.getListPrice();
      // listingPrice.toFixed();

      console.log(price);
      console.log(listingPrice, "listing price");

      if (checked) {
        let transaction = await contract.createAndListNfts(metadataURL, price, {
          value: listingPrice,
        });
        await transaction.wait();
        successResult();
      } else if (!checked) {
        let transaction = await contract.createNfts(metadataURL, price);
        await transaction.wait();
        alert("Successfully Uploaded your NFT!");
        updateMessage("");
        updateFormParams({ name: "", description: "", price: "" });
        window.location.replace("/");
      }

      function successResult() {
        alert("Successfully listed your NFT!");
        updateMessage("");
        updateFormParams({ name: "", description: "", price: "" });
        window.location.replace("/");
      }
      setCreating(false);
    } catch (e) {
      // console.log(e);
      alert("Upload error" + e);
      setCreating(false);
    }
  }
  console.log("Working", process.env);

  return (
    <div className="create-cont">
      <h1>Create Nft</h1>
      <form className="create-form">
        <div class="create-upload-btn-wrapper">
          <img src={Upload} alt="upload" className="create-upload-icon" />
          <label class="create-upload-btn">
            Drag & Drop or <span>choose file </span>to upload
            <input type="file" name="myfile" onChange={OnChangeFile} />
            {fileName && (
              <h4 id="fileName" className="create_file_uploaded">
                Loading....{fileName.name}
              </h4>
            )}
            <p>Upload image, Video, Audio or 3D model</p>
          </label>
        </div>

        <div className="create-input">
          <label for="name">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Item name"
            onChange={(e) =>
              updateFormParams({ ...formParams, name: e.target.value })
            }
            value={formParams.name}
          ></input>
        </div>
        <div className="create-input">
          <label for="external-link">External Link (optional)</label>
          <input
            type="url"
            id="link"
            placeholder="https://yoursite.io/item/123"
          />
        </div>
        <div className="create-input">
          <label for="description">Description</label>
          <textarea
            type="text"
            id="text"
            placeholder="Provide A Detailed Description of your Item"
            value={formParams.description}
            onChange={(e) =>
              updateFormParams({ ...formParams, description: e.target.value })
            }
          ></textarea>
        </div>
        <div className="create-input">
          <label for="price">Set Price</label>
          <input
            type="number"
            id="text"
            placeholder="Provide A Price for the Item"
            step="0.01"
            value={formParams.price}
            onChange={(e) =>
              updateFormParams({ ...formParams, price: e.target.value })
            }
          ></input>
        </div>
        <div className="create-checkbox">
          <input
            type="checkbox"
            id="checked"
            name="checked"
            onChange={handleCheckbox}
          />
          <p>List this NFT</p>
        </div>
        <div className="create-input">
          <button onClick={createNFT}>Create</button>
          {creating && <div className="loader"></div>}
        </div>
      </form>
    </div>
  );
}
